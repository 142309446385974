import React, { useEffect, useState } from 'react'

import { Frame, useEditor } from '@craftjs/core'
import { DesignInterface } from '../../types/main'
import { getDesign, getDomainDesign, startSession } from '../../services/HelperService'
import { RecipientProvider } from '../../contexts/RecipientContext'

export const Renderer = () => {
  const [variables, setVariables] = useState(null)
  const [recipient, setRecipient] = useState(null)
  const [token, setToken] = useState(null)
  const {actions: {history}} = useEditor()
  const queryParams = new URLSearchParams(window.location.search)

  useEffect(() => {
    let host = window.location.hostname
    let path = window.location.pathname === '/' ? '' : window.location.pathname
    // host = 'www.thisisatestingdomain.com'
    // path = path + '?domain=www.thisisatestingdomain.com'

    if (host.includes('admin.directmail.io')) {
      if (queryParams.get('design')) {
        getDesign(queryParams.get('design'), queryParams.get('campaign_id'))
          .then((response) => {
            if (response.data.status == 'success') {
              let design: DesignInterface = response.data.data   
              history.ignore().deserialize(JSON.parse(design.state))	
            }
          })

        // startSession('/scottcarlson?domain=www.thisisatestingdomain.com')
        //   .then((response) => {
        //     // Recipient data should be set here
        //     setRecipient(response.data.data.recipient || response.data.data.contact)
        //     setVariables(response.data.data.veriables.variables)
        //     setToken(response.data.data.token)
        //   })
      }
    } else {
      getDomainDesign(host)
        .then((response) => {
          if (response.data.status == 'success') {
            let design: DesignInterface = response.data.data   
            history.ignore().deserialize(JSON.parse(design.state))	
          }
        })

      startSession(path)
        .then((response) => {
          let data = response.data.data
          let recipient = {}
          let variables = data.variables?.variables || {}
          if (data.recipient || data.contact) {
            recipient = data.recipient || data.contact
          }
          
          if (data.jumpToRedemption) {
            recipient = {
              ...recipient,
              redemption: data.redemptionOptions
            }
          }

          setRecipient(recipient)
          setVariables(variables)
          setToken(data.token)
        })
    }
  }, [])

  return <RecipientProvider value={[recipient, token, setRecipient, variables]}>
    <Frame></Frame>
  </RecipientProvider>
}